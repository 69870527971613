import { useReducer, createContext } from "react";

const initialState = {
  data: [],
};

const AssignmentContext = createContext(initialState);


function assignmentReducer(state, action) {

  switch (action.type) {
    case "ADD_QUESTION":
        const qn = state.data.find((item, i) => item.question === action.payload);

        const dt = state.data;

        if(!qn){
            dt.push({
                question: action.payload,
                choices: [],
                answer: {},
              });
        }
        return {data : dt};
    case "REMOVE_QUESTION":
        const filtered = state.data.filter((item, i) => item.question !== action.payload.qn);

        return {data : filtered};
    case "ADD_CHOICE":
        const qn2 = state.data.find((item, i) => item.question === action.payload.qn);

        const exists = qn2.choices.find((it, i) => it === action.payload.query);
        const chs = qn2.choices;
        if(!exists){
        chs.push(action.payload.query)
        }
        const updatedqns1 = state.data.map(item =>
            item.question === action.payload.qn
                ? { ...item, choices: chs }
                : item
            );
        return {data : updatedqns1};

    case "REMOVE_CHOICE":
        let question = action.payload.qn;
        let query = action.payload.query;

        let questionFound = state.data.find((item, i) => item.question === question);
        let chocesFiltered2 = questionFound.choices.filter((itemQuery, i) => itemQuery !== query);


        const updatedqns = state.data.map(item =>
            item.question === action.payload.qn
                ? { ...item, choices: chocesFiltered2 }
                : item
            );

        // state.data = qns
        // console.log(state.data)
        return {data : updatedqns};
    default:
        // console.log(`SA:`,state,action)
        return state;  
    }
}

const AssignmentProvider = ({children}) => {
    const [state, dispatch] = useReducer(assignmentReducer,initialState);

    return (
        <AssignmentContext.Provider value={{ data: state.data, dispatch }}>
            {children}
        </AssignmentContext.Provider>
    );
}

export { AssignmentContext, AssignmentProvider};