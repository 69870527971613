import { Navigate } from "react-router-dom";
import { useAuthState } from "../../utils/context/auth";

const ProtectedAdminRoute = ({ permission, element: Element, ...props }) => {
  const { authState } = useAuthState();
  const { user, isLoading } = authState;

  // console.log("permission : ", permission)
  // console.log("user : ", user)
  // console.log("isLoading : ", isLoading)

  // console.log("shdjsjd")

  if (!isLoading) {
    if (user === null) {
      return <Navigate to="/login" />;
    } else {
      if (user.is_superuser) {
        return <Element {...props} />;
      }

      const perms = [];

      for (var g = 0; g < user.groups.length; g++) {
        user.groups[g].permissions.forEach((perm) => perms.push(perm));
      }

      user.user_permissions.forEach((perm) => perms.push(perm));

      if (user.user_type === "Admin" && user.user_type === permission) {
        return <Element {...props} />;
      }

      for (var i = 0; i < perms.length; i++) {
        if (perms[i].codename === permission) {
          return <Element {...props} />;
        }
      }
    }
  }

  return <Element {...props} />;
};

export default ProtectedAdminRoute;
