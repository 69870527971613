import { useReducer } from 'react'
import { useContext,createContext } from 'react'
import { authReduder } from './reducer'

const initialState = {
    isLoading : false,
    error: null,
    accessToken: localStorage.getItem("userTokens") === null ? null : JSON.parse(localStorage.getItem("userTokens")).access,
    refreshToken: localStorage.getItem("userTokens") === null ? null : JSON.parse(localStorage.getItem("userTokens")).refresh,
    // accessToken: JSON.parse(localStorage.getItem("userTokens")).access || null,
    // refreshToken: JSON.parse(localStorage.getItem("userTokens")).refresh || null,
    
    // user : null,
    user: localStorage.getItem("user") === null ? null : JSON.parse(localStorage.getItem("user")),

}

const AuthContext = createContext(initialState)


export const AuthProvider = ({children}) => {

    const [ state, dispatch ] = useReducer(authReduder,initialState)

    return <AuthContext.Provider
    value = {{
        authState: state,
        dispatchAuth: dispatch
    }}
    >
        {children}
    </AuthContext.Provider>
}

export const useAuthState = () => useContext(AuthContext)