import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { AssignmentProvider } from "./utils/context/assignmentContext";
import { AuthProvider } from "./utils/context/auth";
import FormApp from "./test_ui/multi_step_form/FormApp";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
      <AuthProvider>
        <AssignmentProvider>
          <BrowserRouter>
            <App />
            {/* <FormApp/> */}
          </BrowserRouter>
        </AssignmentProvider>
      </AuthProvider>
);
