import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import Loadable from "../layouts/shared/loadable/Loadable";
import ProtectedHomeRoute from "./protected_home";
import ProtectedAdminRoute from "./protected/protected_admin_routes";
import ProtectedStaffRoute from "./protected/protected_staff_routes";
import ProtectedTeacherRoute from "./protected/protected_teacher_routes";
import ProtectedStudentRoute from "./protected/protected_student_routes";
import ProtectedLoginRoute from "./protected_login";

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import("../layouts/full/FullLayout")));
const BlankLayout = Loadable(
  lazy(() => import("../layouts/blank/BlankLayout"))
);

/* ****Pages***** */
const Error = Loadable(lazy(() => import("../layouts/error/Error")));
const Login = Loadable(lazy(() => import("../pages/auth/login")));
const Dashboard = Loadable(lazy(() => import("../pages/dashboard")));
const HomeAdmin = Loadable(lazy(() => import("../pages/home/home_admin")));
const HomeStaff = Loadable(lazy(() => import("../pages/home/home_staff")));
const HomeTeacher = Loadable(lazy(() => import("../pages/home/home_teacher")));
const HomeStudent = Loadable(lazy(() => import("../pages/home/home_student")));

const Student = Loadable(lazy(() => import("../pages/students")));
const StudentNew = Loadable(lazy(() => import("../pages/students/new")));

/* **** Employees ***** */
const Teacher = Loadable(lazy(() => import("../pages/employee/teachers")));
const TeacherNew = Loadable(
  lazy(() => import("../pages/employee/teachers/new"))
);
const Staff = Loadable(lazy(() => import("../pages/employee/staff")));
const StaffNew = Loadable(lazy(() => import("../pages/employee/staff/new")));
const Admin = Loadable(lazy(() => import("../pages/employee/admin")));
const AdminNew = Loadable(lazy(() => import("../pages/employee/admin/new")));

const Users = Loadable(lazy(() => import("../pages/access_control/users")));
const UsersRolesEdit = Loadable(
  lazy(() => import("../pages/access_control/users/roles_edit"))
);

const Roles = Loadable(lazy(() => import("../pages/access_control/roles")));
const RolesPermissionsEdit = Loadable(
  lazy(() => import("../pages/access_control/roles/permissions_edit"))
);
const Classes = Loadable(lazy(() => import("../pages/utilities/classes")));
const StudentsPerClass = Loadable(
  lazy(() => import("../pages/utilities/classes/students"))
);
const Subjects = Loadable(lazy(() => import("../pages/utilities/subjects")));
const BursaryOptions = Loadable(
  lazy(() => import("../pages/utilities/bursary_options"))
);

const TermPlanner = Loadable(lazy(() => import("../pages/utilities/term")));

const SchoolFeesStructure = Loadable(
  lazy(() => import("../pages/schoolFees/structure/Structure"))
);

const SchoolFeesStructureNew = Loadable(
  lazy(() => import("../pages/schoolFees/structure/StructureNew"))
);

const SchoolFeesStructureDetail = Loadable(
  lazy(() => import("../pages/schoolFees/structure/StructureDetail"))
);

const ExtraFeesPayment = Loadable(
  lazy(() => import("../pages/schoolFees/payment/index_extra"))
);

const TuitionFeesPayment = Loadable(
  lazy(() => import("../pages/schoolFees/payment/index_tuition"))
);
const TuitionFeesPaymentDetail = Loadable(
  lazy(() => import("../pages/schoolFees/payment/detail_tuition"))
);
const ExtraFeesPaymentDetail = Loadable(
  lazy(() => import("../pages/schoolFees/payment/detail_extra"))
);
// const SchoolFeesPaymentNew = Loadable(
//   lazy(() => import("../pages/schoolFees/payment/new"))
// );
const NewExtraFeesPayment = Loadable(
  lazy(() => import("../pages/schoolFees/payment/new_extra"))
);
const NewTuitionFeesPayment = Loadable(
  lazy(() => import("../pages/schoolFees/payment/new_tuition"))
);
const StudentPaymentProfile = Loadable(
  lazy(() => import("../pages/schoolFees/payment/profile"))
);

const GradesTypes = Loadable(lazy(() => import("../pages/grades/Types/Types")));

const Assessments = Loadable(
  lazy(() => import("../pages/grades/Assessments/Assessments"))
);

const AssessmentsResults = Loadable(
  lazy(() => import("../pages/grades/Assessments/AssessmentsResults"))
);

const AssessmentsResultsEdit = Loadable(
  lazy(() => import("../pages/grades/Assessments/AssessmentsResultsEdit"))
);

const Router = [
  {
    path: "/home",
    element: <FullLayout />,
    children: [
      {
        path: "/home",
        element: <ProtectedHomeRoute element={FullLayout} />,
      },
      {
        path: "/home/dashboard/admin",
        exact: true,
        element: <ProtectedAdminRoute permission="admin" element={HomeAdmin} />,
      },

      {
        path: "/home/dashboard/staff",
        exact: true,
        element: <ProtectedAdminRoute permission="admin" element={HomeStaff} />,
      },

      {
        path: "/home/dashboard/teacher",
        exact: true,
        element: (
          <ProtectedAdminRoute permission="admin" element={HomeTeacher} />
        ),
      },

      {
        path: "/home/dashboard/student",
        exact: true,
        element: (
          <ProtectedAdminRoute permission="admin" element={HomeStudent} />
        ),
      },

      { path: "/home/student", exact: true, element: <Student /> },
      { path: "/home/student/new", exact: true, element: <StudentNew /> },

      { path: "/home/teacher", exact: true, element: <Teacher /> },
      { path: "/home/teacher/new", exact: true, element: <TeacherNew /> },

      { path: "/home/staff", exact: true, element: <Staff /> },
      { path: "/home/staff/new", exact: true, element: <StaffNew /> },

      { path: "/home/admin", exact: true, element: <Admin /> },
      { path: "/home/admin/new", exact: true, element: <AdminNew /> },

      { path: "/home/user", exact: true, element: <Users /> },
      {
        path: "/home/user/roles/:id",
        exact: true,
        element: <UsersRolesEdit />,
      },

      { path: "/home/role", exact: true, element: <Roles /> },
      {
        path: "/home/role/perms/:id",
        exact: true,
        element: <RolesPermissionsEdit />,
      },

      { path: "/home/classes", exact: true, element: <Classes /> },
      {
        path: "/home/classes/students",
        exact: true,
        element: <StudentsPerClass />,
      },
      { path: "/home/subjects", exact: true, element: <Subjects /> },
      {
        path: "/home/bursary-options",
        exact: true,
        element: <BursaryOptions />,
      },

      { path: "/home/terms", exact: true, element: <TermPlanner /> },

      { path: "/home/grades/types", exact: true, element: <GradesTypes /> },

      { path: "/home/assessments", exact: true, element: <Assessments /> },
      {
        path: "/home/assessments/results/:id",
        exact: true,
        element: <AssessmentsResults />,
      },
      {
        path: "/home/assessments/results/edit/:id",
        exact: true,
        element: <AssessmentsResultsEdit />,
      },

      {
        path: "/home/school-fees",
        exact: true,
        element: <SchoolFeesStructure />,
      },

      {
        path: "/home/school-fees/add",
        exact: true,
        element: <SchoolFeesStructureNew />,
      },

      {
        path: "/home/school-fees/tuition-detail/:id",
        exact: true,
        element: <SchoolFeesStructureDetail />,
      },
      {
        path: "/home/school-fees/extra-detail/:id",
        exact: true,
        element: <SchoolFeesStructureDetail />,
      },
      // {
      //   path: "/home/school-fees/payment",
      //   exact: true,
      //   element: <SchoolFeesPayment />,
      // },
      {
        path: "/home/school-fees/extra-payment",
        exact: true,
        element: <ExtraFeesPayment />,
      },
      {
        path: "/home/school-fees/tuition-payment",
        exact: true,
        element: <TuitionFeesPayment />,
      },
      // {
      //   path: "/home/school-fees/payment/new/",
      //   exact: true,
      //   element: <SchoolFeesPaymentNew />,
      // },
      {
        path: "/home/school-fees/extra-payment/new/",
        exact: true,
        element: <NewExtraFeesPayment />,
      },
      {
        path: "/home/school-fees/tuition-payment/new/",
        exact: true,
        element: <NewTuitionFeesPayment />,
      },
      {
        path: "/home/school-fees/tuition-payment/detail/:id",
        exact: true,
        element: <TuitionFeesPaymentDetail />,
      },
      {
        path: "/home/school-fees/extra-payment/detail/:id",
        exact: true,
        element: <ExtraFeesPaymentDetail />,
      },
      {
        path: "/home/school-fees/payment/profile/:id/",
        exact: true,
        element: <StudentPaymentProfile />,
      },

      { path: "/home/404", exact: true, element: <Error /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },

  {
    path: "/",
    element: <BlankLayout />,
    children: [
      { path: "", element: <Navigate to="/login" /> },
      {
        path: "login",
        element: <ProtectedLoginRoute element={Login} />,
      },
      // { path: "login", exact: true, element: <Login /> },
      { path: "404", exact: true, element: <Error /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },

  // {
  //   path: "/",
  //   element: <BlankLayout />,
  //   children: [
  //     { path: "/", element: <Navigate to="/login" /> },
  //     { path: "/login", exact: true, element: <Login /> },
  //     { path: "/404", exact: true, element: <Error /> },

  //     { path: "/test1", exact: true, element: <div>test1</div> },
  //     { path: "/real1", exact: true, element: <div>real1</div> },
  //     { path: "*", element: <Navigate to="/404" /> },
  //   ],
  // },
];

export default Router;

// const TestRouter = [
//   {
//     path: "/home",
//     element: <FullLayout />,
//     children: [
//       { path: "", element: <Navigate to="home/admin" /> },
//       {
//         path: "admin",
//         element: <ProtectedAdminRoute permission="admin" element={HomeAdmin} />,
//       },
//       {
//         path: "student",
//         element: <ProtectedAdminRoute permission="student" element={Student} />,
//       },
//       {
//         path: "student/new",
//         element: <ProtectedAdminRoute permission="student" element={StudentNew} />,
//       },
//     ],
//   },

//   {
//     path: "/home/staff",
//     element: <ProtectedStaffRoute permission="Staff" element={FullLayout} />,
//     children: [
//       {
//         path: "",
//         element: <ProtectedStaffRoute permission="staff" element={HomeStaff} />,
//       },
//       {
//         path: "student",
//         element: <ProtectedStaffRoute permission="student" element={Student} />,
//       },
//       {
//         path: "student/new",
//         element: <ProtectedStaffRoute permission="student" element={StudentNew} />,
//       },
//     ],
//   },

//   {
//     path: "/home/teacher",
//     element: <ProtectedTeacherRoute permission="Teacher" element={FullLayout} />,
//     children: [
//       {
//         path: "",
//         element: <ProtectedTeacherRoute permission="teacher" element={HomeTeacher} />,
//       },
//       {
//         path: "student",
//         element: <ProtectedTeacherRoute permission="student" element={Student} />,
//       },
//       {
//         path: "student/new",
//         element: <ProtectedTeacherRoute permission="student" element={StudentNew} />,
//       },
//     ],
//   },

//   {
//     path: "/home/student",
//     element: <ProtectedStudentRoute permission="Student" element={FullLayout} />,
//     children: [
//       {
//         path: "",
//         element: <ProtectedStudentRoute permission="student" element={HomeStudent} />,
//       },
//       {
//         path: "student",
//         element: <ProtectedStudentRoute permission="student" element={Student} />,
//       },
//       {
//         path: "student/new",
//         element: <ProtectedStudentRoute permission="student" element={StudentNew} />,
//       },
//     ],
//   },

//   {
//     path: "/",
//     element: <BlankLayout />,
//     children: [
//       { path: "", element: <Navigate to="/login" /> },
//       {
//         path: "login",
//         element: <ProtectedLoginRoute element={Login} />,
//       },
//       // { path: "login", exact: true, element: <Login /> },
//       { path: "404", exact: true, element: <Error /> },
//       { path: "*", element: <Navigate to="/404" /> },
//     ],
//   },

//   // {
//   //   path: "/home",
//   //   element: <FullLayout />,
//   //   children: [
//   //     { path: "/home/", element: <HomeAdmin /> },
//   //     // { path: "/home", element: <Navigate to="/home/dashboard" /> },
//   //     // { path: "/home/dashboard", element: <Navigate to="/home/dashboard/admin" /> },
//   //     // { path: "/home/dashboard", exact: true, element: <Dashboard /> },
//   //     { path: "/home/dashboard/admin", exact: true, element: <HomeAdmin /> },
//   //     { path: "/home/dashboard/staff", exact: true, element: <HomeStaff /> },
//   //     { path: "/home/dashboard/teacher", exact: true, element: <HomeTeacher/> },
//   //     { path: "/home/dashboard/student", exact: true, element: <HomeStudent /> },

//   //     {
//   //       path: '/home/admin2',
//   //       element: <ProtectedRoute permission="user" element={HomeStudent } />
//   //     },

//   //     { path: "/home/student", exact: true, element: <Student /> },
//   //     { path: "/home/student/new", exact: true, element: <StudentNew /> },

//   //     { path: "/home/teacher", exact: true, element: <Teacher /> },
//   //     { path: "/home/teacher/new", exact: true, element: <TeacherNew /> },

//   //     { path: "/home/staff", exact: true, element: <Staff /> },
//   //     { path: "/home/staff/new", exact: true, element: <StaffNew /> },

//   //     { path: "/home/admin", exact: true, element: <Admin /> },
//   //     { path: "/home/admin/new", exact: true, element: <AdminNew /> },

//   //     { path: "/home/user", exact: true, element: <Users /> },
//   //     { path: "/home/user/roles/:id", exact: true, element: <UsersRolesEdit /> },

//   //     { path: "/home/role", exact: true, element: <Roles /> },
//   //     {
//   //       path: "/home/role/perms/:id",
//   //       exact: true,
//   //       element: <RolesPermissionsEdit />,
//   //     },

//   //     { path: "/home/classes", exact: true, element: <Classes /> },
//   //     {
//   //       path: "/home/classes/students",
//   //       exact: true,
//   //       element: <StudentsPerClass />,
//   //     },
//   //     { path: "/home/subjects", exact: true, element: <Subjects /> },
//   //     { path: "/home/bursary-options", exact: true, element: <BursaryOptions /> },

//   //     { path: "/home/terms", exact: true, element: <TermPlanner /> },

//   //     { path: "/home/grades/types", exact: true, element: <GradesTypes /> },

//   //     { path: "/home/assessments", exact: true, element: <Assessments /> },
//   //     { path: "/home/assessments/results/:id", exact: true, element: <AssessmentsResults /> },
//   //     { path: "/home/assessments/results/edit/:id", exact: true, element: <AssessmentsResultsEdit /> },

//   //     { path: "/home/school-fees", exact: true, element: <SchoolFeesStructure /> },

//   //     { path: "/home/school-fees/add", exact: true, element: <SchoolFeesStructureNew /> },

//   //     {
//   //       path: "/home/school-fees/tuition-detail/:id",
//   //       exact: true,
//   //       element: <SchoolFeesStructureDetail />,
//   //     },
//   //     {
//   //       path: "/home/school-fees/extra-detail/:id",
//   //       exact: true,
//   //       element: <SchoolFeesStructureDetail />,
//   //     },
//   //     // {
//   //     //   path: "/home/school-fees/payment",
//   //     //   exact: true,
//   //     //   element: <SchoolFeesPayment />,
//   //     // },
//   //     {
//   //       path: "/home/school-fees/extra-payment",
//   //       exact: true,
//   //       element: <ExtraFeesPayment />,
//   //     },
//   //     {
//   //       path: "/home/school-fees/tuition-payment",
//   //       exact: true,
//   //       element: <TuitionFeesPayment />,
//   //     },
//   //     // {
//   //     //   path: "/home/school-fees/payment/new/",
//   //     //   exact: true,
//   //     //   element: <SchoolFeesPaymentNew />,
//   //     // },
//   //     {
//   //       path: "/home/school-fees/extra-payment/new/",
//   //       exact: true,
//   //       element: <NewExtraFeesPayment />,
//   //     },
//   //     {
//   //       path: "/home/school-fees/tuition-payment/new/",
//   //       exact: true,
//   //       element: <NewTuitionFeesPayment />,
//   //     },
//   //     {
//   //       path: "/home/school-fees/tuition-payment/detail/:id",
//   //       exact: true,
//   //       element: <TuitionFeesPaymentDetail />,
//   //     },
//   //     {
//   //       path: "/home/school-fees/extra-payment/detail/:id",
//   //       exact: true,
//   //       element: <ExtraFeesPaymentDetail/>,
//   //     },
//   //     {
//   //       path: "/home/school-fees/payment/profile/:id/",
//   //       exact: true,
//   //       element: <StudentPaymentProfile />,
//   //     },

//   //     { path: '/home/404', exact: true, element: <Error /> },
//   //     { path: '*', element: <Navigate to="/404" /> },
//   //   ],
//   // },
// ];
