import { ColorModeContext, useMode } from "./utils/theme";
import { useRoutes } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import TestRouter from "./routes/TestRouter";


function App() {
  // const routing = useRoutes(Router);
  const routing = useRoutes(TestRouter);

  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {routing}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
