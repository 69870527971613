export const authReduder = (state, action) => {
  switch (action.type) {
    case "LOGIN_REQUEST":
      return {
        ...state,
        isLoading: true,
        error: null,
        accessToken: null,
        refreshToken: null,
        user: null,
      };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        isLoading: false,
        error: null,
        accessToken: action.payload.access,
        refreshToken: action.payload.access,
      };
    case "LOGIN_FAILURE":
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        accessToken: null,
        refreshToken: null,
      };
    case "USER_DETAILS_REQUEST":
      return {
        ...state,
        isLoading: true,
        error: null,
        // user: null,
      };
    case "USER_DETAILS_SUCCESS":
      localStorage.setItem("user", JSON.stringify(action.payload));
      return {
        ...state,
        isLoading: false,
        error: null,
        user: action.payload,
      };
    case "USER_DETAILS_FAILURE":
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        // user: null,
      };
      
    case "LOGOUT":
      localStorage.clear();
      return {
        ...state,
        error: null,
        user: null,
        accessToken: null,
        refreshToken: null,
      };
    default:
      return state;
  }
};
