import { createTheme,responsiveFontSizes } from "@mui/material/styles";
import { cyan } from "@mui/material/colors";
// import { makeStyles } from "@mui/material/styles/makeStyles"

let theme = createTheme({
    palette: { type: 'dark', primary:cyan, secondary:cyan}
})

theme = responsiveFontSizes(theme)

// const useStyles = makeStyles(() => ({
//     root: {
//         width:'auto',
//         marginLeft: theme.spacing(2),
//         marginRight: theme.spacing(2),
//         backgroundColor: theme.palette.background.default,
//         color:theme.palette.text.primary
//     },
//     paper: {
//         marginTop: theme.spacing(3),
//         marginBottom: theme.spacing(3),
//         padding: theme.spacing(2)
//     }
// }))

// export {theme, useStyles}
export {theme}
