import React from "react";
import { Paper, CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material";
import MyHeader from "./MyHeader";
import MyFooter from "./MyFooter";
import { theme } from "./css/styles";

const FormLayout = (props) => {
  // const classes = useStyles()
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MyHeader />
      <div
        style={{
          width: "auto",
          marginLeft: theme.spacing(2),
          marginRight: theme.spacing(2),
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
        }}
        // className={classes.root}
      >
        <Paper
          style={{
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            padding: theme.spacing(2),
          }}
          // className={classes.paper}
        >
          {props.children}
        </Paper>
      </div>
      <MyFooter />
    </ThemeProvider>
  );
};

export default FormLayout;
