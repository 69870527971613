import { Navigate } from 'react-router-dom';
import { useAuthState } from '../utils/context/auth';

const ProtectedHomeRoute = ({element: Element, ...props }) => {

  const { authState } = useAuthState();
  const { user,isLoading } = authState;

//   console.log(user)

  // console.log("permission : ", permission)
  // console.log("user : ", user)
  // console.log("isLoading : ", isLoading)

  if(!isLoading){
    if(user === null){
        return <Navigate to="/404" />;
        // return <Element {...props} />;
    }else{
        if(user.user_type === "Admin"){
            return <Navigate to="/home/dashboard/admin" />;
        }
        if(user.user_type === "Staff"){
            return <Navigate to="/home/dashboard/staff" />;
        }
        if(user.user_type === "Teacher"){
            return <Navigate to="/home/dashboard/teacher" />;
        }
        if(user.user_type === "Student"){
            return <Navigate to="/home/dashboard/student" />;
        }
    }
  }
  return <Element {...props} />;
};

export default ProtectedHomeRoute;
